import { createSlice } from "@reduxjs/toolkit";

const primarySlice = createSlice({
    name: "primarySlice",
    initialState: {
        "primary": "",
    },
    reducers: {
        setPrimaries(state, action) {
            state.primary = action.payload
        },
    },
});

export default primarySlice.reducer;
export const { setPrimaries } = primarySlice.actions;
