import { configureStore } from "@reduxjs/toolkit";
import TitleBorSlice from "./slices/TitleBorSlice";
import MetaDataSlice from "./slices/MetaDataSlice";
import DistrictsSlice from "./slices/DistrictsSlice";
import BlockSlice from "./slices/BlocksSlice";
import PrimarySlice from "./slices/PrimariesSlice";
import TokenSlice from "./slices/TokenSlice";
import BoothSlice from "./slices/BoothSlice";
import memberSlice from "./slices/AddMenberCartSlice";
const store = configureStore({
  reducer: {
    titleBar: TitleBorSlice,
    metaData: MetaDataSlice,
    districts: DistrictsSlice,
    blocks: BlockSlice,
    primaries: PrimarySlice,
    booths: BoothSlice,
    token: TokenSlice,
    members: memberSlice,
  },
});
export default store;
