import { createSlice } from "@reduxjs/toolkit";

const boothSlice = createSlice({
    name: "boothSlice",
    initialState: {
        "booth": "",
    },
    reducers: {
        setBooths(state, action) {
            state.booth = action.payload
        },
    },
});

export default boothSlice.reducer;
export const { setBooths } = boothSlice.actions;
