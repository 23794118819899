import { createSelector, createSlice } from "@reduxjs/toolkit";


let tempArray = []
const memberSlice = createSlice({
    name: "members",
    initialState: [],
    reducers: {
        addMemberToCart(state, action) {
            state.push(action.payload);
        },
        removeMemberCart(state, action) {
            console.log(action.payload)
            state.splice(action.payload, 1);
        },
        resetCart(state, action) {
            return [];
        }
    }
});




export default memberSlice.reducer;
export const { addMemberToCart, removeMemberCart, resetCart } = memberSlice.actions;