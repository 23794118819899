import { createSlice } from "@reduxjs/toolkit";

const blockSlice = createSlice({
    name: "blockSlice",
    initialState: {
        "block": "",
    },
    reducers: {
        setBlocks(state, action) {
            state.block = action.payload
        },
    },
});

export default blockSlice.reducer;
export const { setBlocks } = blockSlice.actions;
