import { createSlice } from "@reduxjs/toolkit";

const titleBarSlice = createSlice({
  name: "titleSlice",
  initialState: {
    manu: "",
  },
  reducers: {
    setManu(state, action) {
      state.manu = action.payload;
    },
  },
});

export default titleBarSlice.reducer;
export const { setManu } = titleBarSlice.actions;
