import React, { Suspense, lazy } from 'react';
import PageLoading from './components/loader/PageLoading';
import axios from 'axios';




// For  authontication

//axios.defaults.baseURL = "http://localhost:3000/dev/";
axios.defaults.baseURL = "https://c6ft3h3e2f.execute-api.us-east-1.amazonaws.com/dev/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;


export default function App() {

  //const Index = lazy(() => import('./user/Index'));

  const Global = lazy(() => import('./route/Global'));
  const Admin = lazy(() => import('./route/Admin'));
  const Supervisor = lazy(() => import('./route/Supervisor'));
  const Agent = lazy(() => import('./route/Agent'));
  const President = lazy(() => import('./route/President'));
  const ResourcePerson = lazy(() => import('./route/ResourcePerson'));


  // Use for production
  //console.log = console.warn = console.error = () => { };

  // Use for development
  console.warn = console.warn = () => { };
  return (
    <>

      <Suspense fallback={<PageLoading />}>
        <Global />
      </Suspense>

      <Suspense fallback={<PageLoading />}>
        <Admin />
      </Suspense>

      <Suspense fallback={<PageLoading />}>
        <Supervisor />
      </Suspense>

      <Suspense fallback={<PageLoading />}>
        <Agent />
      </Suspense>

      <Suspense fallback={<PageLoading />}>
        <President />
      </Suspense>

      <Suspense fallback={<PageLoading />}>
        <ResourcePerson />
      </Suspense>

    </>
  );

}

