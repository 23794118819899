import Spinner from 'react-bootstrap/Spinner';
function PageLoading() {
    return (
        <>
            <div style={{ position: 'absolute', top: '50%', left: '50%', display: 'table', margin: 'auto' }}>
                <Spinner animation="grow" variant="primary" />
                <Spinner animation="grow" variant="danger" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="success" />
            </div>

        </>
    );
}
export default PageLoading;