import { createSlice } from "@reduxjs/toolkit";

const districtSlice = createSlice({
    name: "districtSlice",
    initialState: {
        "dist": "",
    },
    reducers: {
        setDistricts(state, action) {


            //console.log("Redux:")
            //console.log(action.payload)
            state.dist = action.payload

        },
    },
});

export default districtSlice.reducer;
export const { setDistricts } = districtSlice.actions;
