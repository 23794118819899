import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
    name: "tokenSlice",
    initialState: {
        name: "",
        phone_no: "",
        role: "",
        member_id: "",
        committee_id: "",
        accessibility: "",
        photo: "",
        token: "",
    },
    reducers: {
        setTokenName(state, action) {
            state.name = action.payload
        },
        setTokenPhoneNo(state, action) {
            state.phone_no = action.payload
        },
        setTokenRole(state, action) {
            state.role = action.payload
        },
        setTokenmemberId(state, action) {
            state.member_id = action.payload
        },
        setTokenCommittee(state, action) {
            state.committee_id = action.payload
        },
        setTokenAccessibility(state, action) {
            state.accessibility = action.payload
        },
        setPhoto(state, action) {
            state.photo = action.payload
        },
        setToken(state, action) {
            state.token = action.payload
        },

    },
});

export default tokenSlice.reducer;
export const { setTokenName, setTokenPhoneNo, setTokenRole, setTokenmemberId, setTokenCommittee, setTokenAccessibility, setPhoto, setToken } = tokenSlice.actions;
