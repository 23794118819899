import { createSlice } from "@reduxjs/toolkit";
const metaDataSlice = createSlice({
    name: "metaDataEntrySelect",
    initialState: {
        manu: ''
    },
    reducers: {
        setCommityEntry(state, action) {
            state.manu = action.payload;
        }
    }
});
export default metaDataSlice.reducer;
export const { setCommityEntry } = metaDataSlice.actions;